import React, { useState, useContext } from "react";
import {
  Dialog,
  Grid,
  TextField,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@material-ui/core";

import _ from "lodash";
import moment from "moment";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { AuthContext } from "../../stores/AuthProvider";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { loader } from "graphql.macro";
import Loading from "../Loading";
import ComboSigner from "../../components/ComboSigner";

const VEHICLE_DETAIL = loader("./graphql/VehicleDetail.gql");
const INSERT_CERTIFICATE = loader("./graphql/InsertCertificate.gql");
const CERTIFICATE_AGG = loader("./graphql/DltCertificateAgg.gql");

export default () => {
  const { id } = useParams();
  const { url } = useRouteMatch();
  const { userProfile } = useContext(AuthContext);
  const [signer, setSigner] = useState(null);
  const [numberUser, setNumberUser] = useState(null);
  const [remark, setRemark] = useState(null);
  const history = useHistory();
  const { data: vehicleData } = useQuery(VEHICLE_DETAIL, {
    variables: {
      id,
    },
  });
  const [insertCertificate] = useMutation(INSERT_CERTIFICATE);
  const { loading, data } = useQuery(CERTIFICATE_AGG, {
    variables: {
      where: {
        created_at: {
          _gte: moment().startOf("year").format(),
        },
      },
    },
  });

  if (loading) return <Loading />;

  const { vehicle } = vehicleData || {};
  const { dlt_certificate_aggregate } = data || {};
  const { aggregate } = dlt_certificate_aggregate || {};
  const { max } = aggregate || {};
  const { number } = max || {};

  let year = (moment().year() + 543).toString().slice(2);
  let number_value = (number || 0) + 1;

  const handleClose = () => {
    history.goBack();
  };

  const handleSave = () => {
    insertCertificate({
      variables: {
        data: {
          year,
          number: numberUser || number_value,
          signer_id: signer.id,
          create_uid: userProfile.email,
          vehicle_id: id,
          company_id: _.get(vehicle, "company.company_id"),
          remark,
          payload: {
            year,
            number: numberUser || number_value,
            signer: signer.signer_name,
            position: signer.signer_position,
            dlt_company_name:
              _.get(vehicle, "company.dlt_company_name") ||
              _.get(vehicle, "company.company_name"),
            dlt_vehicle_code:
              _.get(vehicle, "dlt_vehicle_code") ||
              _.get(vehicle, "vehicle_code"),
            province_name: _.get(vehicle, "province.province_name"),
            certification_id: _.get(vehicle, "unit.model.certification_id"),
            unit_id: _.get(vehicle, "unit.unit_id"),
            vehicle_chassis_no: _.get(vehicle, "unit.vehicle_chassis_no"),
            box_type: _.get(vehicle, "unit.model.box_type"),
            box_model: _.get(vehicle, "unit.model.box_model"),
            edc_type: _.get(vehicle, "unit.model.edc_type"),
            edc_model: _.get(vehicle, "unit.model.edc_model"),
            setup_date: _.get(vehicle, "unit.setup_date"),
            remark,
          },
        },
      },
    }).then(({ data }) => {
      if (!loading)
        history.replace(
          `${url.replace("new", "")}/${_.get(
            data,
            "insert_dlt_certificate_one.id"
          )}`
        );
    });
  };

  if (_.isEmpty(number))
    return (
      <Dialog open onClose={handleClose}>
        <DialogTitle>ออกหนังสือรับรอง</DialogTitle>
        <Divider />
        <DialogContent>
          <Box py={1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  size="small"
                  variant="outlined"
                  label="เลขที่หนังสือ"
                  fullWidth
                  defaultValue={number_value}
                  type="number"
                  onChange={(event) => {
                    setNumberUser(event.target.value || number_value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  variant="outlined"
                  label="หมายเหตุ"
                  fullWidth
                  onChange={(event) => {
                    setRemark(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ComboSigner
                  size="small"
                  onChange={(event, newValue) => {
                    setSigner(newValue);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <Divider />
        <DialogContent>
          <Box py={1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="เลขที่หนังสือ"
                  fullWidth
                  size="small"
                  variant="filled"
                  disabled
                  value={`XS-${year}${_.padStart(
                    numberUser || number_value,
                    4,
                    "0"
                  )}`}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="หมายเหตุ"
                  fullWidth
                  size="small"
                  variant="filled"
                  disabled
                  value={remark || "-"}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="ลงชื่อ"
                  fullWidth
                  size="small"
                  variant="filled"
                  disabled
                  value={(signer && signer.signer_name) || "-"}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="ตำแหน่ง"
                  fullWidth
                  size="small"
                  variant="filled"
                  disabled
                  value={(signer && signer.signer_position) || "-"}
                ></TextField>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose}>ยกเลิก</Button>
          <Button
            color="primary"
            disabled={_.isEmpty(signer)}
            onClick={handleSave}
          >
            ยืนยัน
          </Button>
        </DialogActions>
      </Dialog>
    );
};
