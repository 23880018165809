import React, { useContext } from "react";
import { Grid, TextField, InputAdornment } from "@material-ui/core";

import _ from "lodash";

import ComboProject from "../../components/ComboProject";
import ComboCompany from "../../components/ComboCompany";
import { VehicleContext } from "./VehicleProvider";
import ComboCategory from "../../components/ComboCategory";
import ComboType from "../../components/ComboType";
import ComboStatus from "../../components/ComboStatus";
import ComboPay from "../../components/ComboPay";
import ComboDlt from "../../components/ComboDlt";
import ComboRemark from "../../components/ComboRemark";
import { DatePicker } from "@material-ui/pickers";
import { Search } from "@material-ui/icons";

export default () => {
  const { projectId, dltEnable, taxDate, setFilter } = useContext(
    VehicleContext
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TextField
          size="small"
          variant="outlined"
          label="ค้นหา ทะเบียน, เลขกล่อง"
          fullWidth
          onChange={(event) => {
            setFilter({
              searchText: event.target.value,
            });
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search style={{ color: "grey" }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <ComboProject
          size="small"
          onChange={(o, item) => {
            setFilter({
              projectId: _.get(item, "project_id") || null,
              categoryId: null,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <ComboCompany
          size="small"
          projectId={projectId}
          onChange={(o, item) => {
            setFilter({
              companyId: _.get(item, "company_id") || null,
            });
          }}
        />
      </Grid>
      {projectId && (
        <>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <ComboCategory
              size="small"
              projectId={projectId}
              onChange={(o, item) => {
                setFilter({ categoryId: _.get(item, "category_id") || null });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <ComboType
              size="small"
              projectId={projectId}
              onChange={(o, item) => {
                setFilter({ typeId: _.get(item, "type_id") || null });
              }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <ComboStatus
          size="small"
          onChange={(o, item) => {
            setFilter({
              boxStatus: _.get(item, "id") || null,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <ComboPay
          size="small"
          onChange={(o, item) => {
            setFilter({
              payId: _.get(item, "pay_id") || null,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <ComboRemark
          size="small"
          onChange={(o, item) => {
            setFilter({
              hasRemark: _.isBoolean(_.get(item, "id"))
                ? _.get(item, "id")
                : null,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <ComboDlt
          size="small"
          inputValue={
            !_.isBoolean(dltEnable) ? "" : dltEnable ? "ใช่" : "ไม่ใช่"
          }
          onChange={(o, item) => {
            setFilter({
              dltEnable: _.isBoolean(_.get(item, "id"))
                ? _.get(item, "id")
                : null,
              taxDate: _.get(item, "id") === true ? taxDate : null,
            });
          }}
        />
      </Grid>
      {dltEnable && (
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <DatePicker
            label="กำหนดต่อภาษี"
            inputVariant="outlined"
            format="MMMM yyyy"
            size="small"
            views={["month"]}
            fullWidth
            autoOk
            clearable
            value={taxDate}
            onChange={(value) => {
              setFilter({
                taxDate: (value && value.toDate()) || null,
              });
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};
