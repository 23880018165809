import React, { useState } from "react";

import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, TextField } from "@material-ui/core";

import _ from "lodash";

import { useQuery } from "@apollo/react-hooks";
import { loader } from "graphql.macro";

const TYPES = loader("./graphql/TypeList.gql");

export default ({ projectId, ...props }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const { loading } = useQuery(TYPES, {
    skip: !open || !projectId,
    variables: {
      where: {
        ...(projectId
          ? {
              project_id: {
                _eq: projectId,
              },
            }
          : {}),
      },
      order_by: [{ type_name: "asc" }],
    },
    onCompleted(data) {
      setOptions(_.get(data, "types") || []);
    },
  });

  return (
    <Autocomplete
      {...props}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options.sort(
        (a, b) =>
          -b.type_name[0]
            .toUpperCase()
            .localeCompare((a.type_name_dlt || a.type_name)[0].toUpperCase())
      )}
      getOptionSelected={(option, value) => option.type_id === value.type_id}
      getOptionLabel={(option) => option.type_name_dlt || option.type_name}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="ประเภทรถ"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
