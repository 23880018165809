import _ from "lodash";
import moment from "moment";

export const VehicleColumns = [
  {
    width: 40,
    cellStyle: (value, rowData) => {
      const box_status = _.get(rowData, "box.box_status");
      const box_gps_time = _.get(rowData, "box.box_gps_time");
      const status = moment()
        .subtract(30, "minutes")
        .isAfter(moment(box_gps_time))
        ? "N"
        : box_status;

      const colors = {
        S: "blue",
        I: "orange",
        M: "green",
        O: "red",
        G: "purple",
        N: "grey",
      };

      return {
        backgroundColor: colors[status] || "lightgrey",
        color: "white",
        textAlign: "center",
      };
    },
    field: "box.box_status",
    render: (rowData) => {
      const box_status = _.get(rowData, "box.box_status");
      const box_gps_time = _.get(rowData, "box.box_gps_time");
      const status = moment()
        .subtract(30, "minutes")
        .isAfter(moment(box_gps_time))
        ? "N"
        : box_status;

      return status || "-";
    },
  },
  {
    title: "เวลาจีพีเอส",
    field: "box.box_gps_time",
    cellStyle: (value) => {
      const notValid = !moment(value || null).isValid();
      const noData = moment().subtract(30, "minutes").isAfter(moment(value));

      return {
        backgroundColor: notValid ? "lightgrey" : noData ? "grey" : null,
        color: noData || notValid ? "white" : null,
      };
    },
    render: (rowData) => {
      const box_gps_time = _.get(rowData, "box.box_gps_time");
      const now = moment();
      const time = moment(box_gps_time);
      const format = time.format("lll");
      const from = time.fromNow();
      const diff = now.diff(time);

      return (box_gps_time && (diff > 18000000 ? from : format)) || "-";
    },
  },
  {
    title: "โปรเจคท์",
    field: "project.project_name",
    hidden: true,
    render: (rowData) => {
      const project_name = _.get(rowData, "project.project_name");
      return project_name || "-";
    },
  },
  {
    title: "บริษัท",
    field: "company.company_name",
    render: (rowData) => {
      const company_name = _.get(rowData, "company.company_name");
      return company_name || "-";
    },
  },
  {
    title: "ทะเบียน",
    field: "vehicle_code",
    render: (rowData) => _.get(rowData, "vehicle_code") || "-",
  },
  {
    title: "ทะเบียน (ส่งกรมฯ)",
    field: "dlt_vehicle_code",
    hidden: true,
    render: (rowData) => _.get(rowData, "dlt_vehicle_code") || "-",
  },
  {
    title: "จังหวัด",
    field: "province.province_name",
    hidden: true,
    render: (rowData) => {
      const province_name = _.get(rowData, "province.province_name");
      return province_name || "-";
    },
  },
  {
    title: "เลขกล่อง",
    field: "box.box_code",
    render: (rowData) => _.get(rowData, "box.box_code") || "-",
  },
  {
    title: "ชนิดกล่อง",
    field: "unit.model.box_type",
    hidden: true,
  },
  {
    title: "แบบกล่อง",
    field: "unit.model.box_model",
    hidden: true,
  },
  {
    title: "ชนิดเครื่องรูด",
    field: "unit.model.edc_type",
    hidden: true,
  },
  {
    title: "แบบเครื่องรูด",
    field: "unit.model.edc_model",
    hidden: true,
  },
  {
    title: "ยี่ห้อรถ",
    field: "unit.vehicle_type",
    hidden: true,
  },
  {
    title: "หมายเลขตัวถัง",
    field: "unit.vehicle_chassis_no",
    hidden: true,
  },
  {
    title: "หมายเลขเครื่อง",
    field: "unit.unit_id",
    hidden: true,
  },
  {
    title: "วันที่ติดตั้ง",
    field: "unit.setup_date",
    hidden: true,
    render: (rowData) => {
      const setup_date = _.get(rowData, "unit.setup_date");
      return setup_date ? moment(setup_date).format("ll") : "-";
    },
  },
  {
    title: "กำหนดต่อภาษี",
    field: "annual_tax_date",
    defaultSort: "asc",
    render: (rowData) => {
      const annual_tax_date = _.get(rowData, "annual_tax_date");
      return annual_tax_date ? moment(annual_tax_date).format("ll") : "-";
    },
  },
];
