import React, { useRef, useState } from "react";

import MaterialTable from "material-table";

import _ from "lodash";
import moment from "moment";

import { useQuery } from "@apollo/react-hooks";
import { loader } from "graphql.macro";

import { useHistory, useRouteMatch } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { useParams } from "react-router-dom";

const DLT_CERTIFICATE = loader("./graphql/DltCertificateList.gql");

export default () => {
  const tableRef = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [variables, setVariables] = useState({});
  const { loading, client, refetch } = useQuery(DLT_CERTIFICATE, {
    skip: true,
  });

  return (
    <>
      <MaterialTable
        title="หนังสือรับรอง"
        isLoading={loading}
        tableRef={tableRef}
        components={{
          Container: (props) => (
            <Paper variant="elevation" elevation={0} square {...props} />
          ),
        }}
        options={{
          search: false,
          pageSize: 5,
          exportAllData: true,
          exportButton: true,
          columnsButton: true,
          padding: "dense",
        }}
        columns={[
          {
            title: "เลขที่",
            field: "number",
            width: 110,
            render: (rowData) => {
              const number = _.get(rowData, "number").toString();
              const created_at = _.get(rowData, "created_at");
              const year = (moment(created_at).year() + 543)
                .toString()
                .slice(2);

              return `XS-${year}${_.padStart(number, 4, "0")}`;
            },
          },
          {
            title: "หมายเหตุ",
            field: "remark",
            render: (rowData) => {
              const remark = _.get(rowData, "remark");

              return remark || "-";
            },
          },
          {
            title: "ผู้ลงนาม",
            field: "signer.signer_name",
            render: (rowData) => {
              const signer_name = _.get(rowData, "signer.signer_name");

              return signer_name || "-";
            },
          },
          {
            title: "ผู้บันทึก",
            field: "create_uid",
            render: (rowData) => {
              const create_uid = _.get(rowData, "create_uid");

              return create_uid || "-";
            },
          },
          {
            title: "วันที่ออกหนังสือ",
            field: "created_at",
            width: 200,
            render: (rowData) => {
              const created_at = _.get(rowData, "created_at");

              return moment(created_at).format("lll");
            },
          },
        ]}
        data={(query) => {
          return new Promise((resolve) => {
            let limit = query.pageSize || 10;
            let offset = query.page * limit || 0;
            let search = query.search || "";
            let order_by = {};
            let where = {};

            if (!_.isEmpty(search)) {
              _.set(where, "number._similar", `%${search}%`);
            }
            if (!_.isEmpty(id)) {
              _.set(where, "vehicle_id._eq", id);
            }

            if (query.orderBy && query.orderBy.field) {
              let fields = _.split(query.orderBy.field, ".");

              order_by = _.reduce(
                _.reverse(fields),
                (a, b) => {
                  return { [b]: a };
                },
                query.orderDirection
              );
            }

            let variables = {
              limit,
              offset,
              where,
              order_by,
            };

            client
              .query({
                query: DLT_CERTIFICATE,
                variables,
              })
              .then(({ data }) => {
                let { dlt_certificate, dlt_certificate_aggregate } = data || {};
                let { aggregate } = dlt_certificate_aggregate || {};

                setVariables(variables);

                return resolve({
                  data: dlt_certificate,
                  page: query.page,
                  totalCount: aggregate && aggregate.count,
                });
              });
          });
        }}
        onRowClick={(event, rowData) => {
          history.push(`${url}/certificate/${rowData.id}`);
        }}
        actions={[
          {
            icon: "add",
            tooltip: "สร้างหนังสือรับรองใหม่",
            isFreeAction: true,
            onClick: () => {
              history.push(`${url}/newcertificate`);
            },
          },
          {
            icon: "refresh",
            tooltip: "รีเฟรชดาต้า",
            isFreeAction: true,
            onClick: () => {
              refetch(variables).then(() => {
                tableRef.current && tableRef.current.onQueryChange();
              });
            },
          },
        ]}
        localization={{
          header: {
            actions: "",
          },
          toolbar: {
            searchPlaceholder: "ค้นหาทะเบียนรถ",
            showColumnsTitle: "แสดงคอลัมน์",
            exportTitle: "ดาวน์โหลดเป็นไฟล์",
            addRemoveColumns: "เพิ่ม/ลบ คอลัมน์",
          },
        }}
      />
    </>
  );
};
