import React, { useRef, useState, useContext, useEffect } from "react";

import MaterialTable from "material-table";

import _ from "lodash";
import moment from "moment";

import { useQuery } from "@apollo/react-hooks";
import { loader } from "graphql.macro";
import { VehicleColumns } from "./VehicleColumns";
import VehicleFilter from "./VehicleFilter";

import { VehicleContext } from "./VehicleProvider";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Box, Paper } from "@material-ui/core";

const VEHICLES = loader("./graphql/VehicleList.gql");

export default () => {
  const tableRef = useRef(null);
  const history = useHistory();
  const { url } = useRouteMatch();
  const [variables, setVariables] = useState({});
  const { vehicleState } = useContext(VehicleContext);
  const { loading, client, refetch } = useQuery(VEHICLES, {
    skip: true,
  });

  useEffect(() => {
    tableRef.current && tableRef.current.setState({});
  }, []);

  useEffect(() => {
    const query = JSON.parse(localStorage.getItem("vehicle.query"));
    tableRef.current &&
      tableRef.current.onQueryChange({
        ...query,
        page: 0,
      });
  }, [vehicleState]);

  return (
    <>
      <Paper variant="outlined" square style={{ borderBottom: 0 }}>
        <Box p={1}>
          <VehicleFilter />
        </Box>
      </Paper>
      <MaterialTable
        title=""
        isLoading={loading}
        tableRef={tableRef}
        components={{
          Container: (props) => <Paper variant="outlined" square {...props} />,
        }}
        options={{
          search: false,
          pageSize: 10,
          exportAllData: true,
          exportButton: true,
          columnsButton: true,
          padding: "dense",
        }}
        columns={VehicleColumns}
        data={(query) => {
          return new Promise((resolve) => {
            let {
              searchText,
              projectId,
              companyId,
              categoryId,
              typeId,
              payId,
              boxStatus,
              hasRemark,
              dltEnable,
              taxDate,
            } = vehicleState;
            let limit = query.pageSize || 10;
            let offset = query.page * limit || 0;
            let search = searchText || "";
            let order_by = {};
            let where = {};

            if (!_.isEmpty(search)) {
              _.set(where, "_or.0.box.box_code._similar", `%${search}%`);
              _.set(where, "_or.1.vehicle_name._similar", `%${search}%`);
              _.set(where, "_or.2.vehicle_code._similar", `%${search}%`);
              _.set(where, "_or.3.dlt_vehicle_code._similar", `%${search}%`);
            }
            if (_.isNumber(projectId)) {
              _.set(where, "project_id._eq", projectId);
            }
            if (_.isNumber(companyId)) {
              _.set(where, "company_id._eq", companyId);
            }
            if (_.isNumber(categoryId)) {
              _.set(where, "category_id._eq", categoryId);
            }
            if (_.isNumber(typeId)) {
              _.set(where, "type_id._eq", typeId);
            }
            if (_.isNumber(payId)) {
              _.set(where, "pay_id._eq", payId);
            }
            if (_.isBoolean(dltEnable)) {
              if (dltEnable === true) {
                _.set(where, "dlt_enable._eq", true);
              } else {
                _.set(where, "dlt_enable._neq", true);
              }
            }
            if (_.isDate(taxDate)) {
              _.set(
                where,
                "annual_tax_date._gte",
                moment(taxDate).startOf("month").format()
              );
              _.set(
                where,
                "annual_tax_date._lte",
                moment(taxDate).endOf("month").format()
              );
            }
            if (_.isString(boxStatus)) {
              if (_.isEqual(boxStatus, "N")) {
                _.set(
                  where,
                  "box.box_gps_time._lte",
                  moment().subtract(30, "minutes").format()
                );
              } else {
                _.set(where, "box.box_status._eq", boxStatus);
                _.set(
                  where,
                  "box.box_gps_time._gt",
                  moment().subtract(30, "minutes").format()
                );
              }
            }
            if (_.isBoolean(hasRemark)) {
              if (hasRemark === true) {
                _.set(where, "remark_id._gt", 0);
              } else {
                _.set(where, "remark_id._eq", 0);
              }
            }

            if (query.orderBy && query.orderBy.field) {
              let fields = _.split(query.orderBy.field, ".");

              order_by = _.reduce(
                _.reverse(fields),
                (a, b) => {
                  return { [b]: a };
                },
                query.orderDirection
              );
            }

            let variables = {
              limit,
              offset,
              where,
              order_by,
            };

            localStorage.setItem("vehicle.query", JSON.stringify(query));

            client
              .query({
                query: VEHICLES,
                variables,
              })
              .then(({ data }) => {
                let { vehicles, vehicles_aggregate } = data || {};
                let { aggregate } = vehicles_aggregate || {};

                setVariables(variables);

                return resolve({
                  data: vehicles,
                  page: query.page,
                  totalCount: aggregate && aggregate.count,
                });
              });
          });
        }}
        onRowClick={(event, rowData) => {
          history.push(`${url}/${_.get(rowData, "vehicle_id")}`);
        }}
        actions={[
          {
            icon: "refresh",
            tooltip: "รีเฟรช",
            isFreeAction: true,
            onClick: () => {
              refetch(variables).then(() => {
                tableRef.current && tableRef.current.onQueryChange();
              });
            },
          },
        ]}
        localization={{
          header: {
            actions: "",
          },
          toolbar: {
            searchPlaceholder: "ค้นหาทะเบียนรถ",
            showColumnsTitle: "แสดงคอลัมน์",
            exportTitle: "ดาวน์โหลดเป็นไฟล์",
            addRemoveColumns: "เพิ่ม/ลบ คอลัมน์",
          },
        }}
      />
    </>
  );
};
