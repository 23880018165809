import React, { useContext, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { makeStyles } from "@material-ui/core";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/th";

import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";

import { AuthContext } from "../stores/AuthProvider";
import PageHeader from "../layouts/PageHeader";
import PageDrawer from "../layouts/PageDrawer";
import Vehicle from "./Vehicle";

const client = new ApolloClient({
  uri: "https://graphql-plus.xsense.co.th/v1/graphql",
  headers: {
    "x-hasura-admin-secret": "xspasswdall",
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
}));

export default () => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <div className={classes.root}>
      {isAuthenticated && (
        <ApolloProvider client={client}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <PageHeader {...{ mobileOpen, setMobileOpen }} />
            <PageDrawer {...{ mobileOpen, setMobileOpen }} />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />

              <Switch>
                <Route path="/vehicle" component={Vehicle} />
                <Route path="/" render={() => <Redirect to="/vehicle" />} />
              </Switch>
            </main>
          </MuiPickersUtilsProvider>
        </ApolloProvider>
      )}
    </div>
  );
};
