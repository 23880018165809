import React from "react";
import {
  ListItemText,
  ListItemIcon,
  MenuList,
  MenuItem,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const menuList = [
  // { icon: "home", title: "แดชบอร์ด", to: "/dashboard" },
  { icon: "truck", title: "ทะเบียนรถ", to: "/vehicle" },
  // { icon: "file-contract", title: "หนังสือรับรอง", to: "/certificate" },
];

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
}));

export default () => {
  const classes = useStyles();
  const location = useLocation();

  const activeRoute = (routeName) => {
    return location.pathname.startsWith(routeName) ? true : false;
  };

  return (
    <MenuList>
      {menuList.map((m, k) => (
        <NavLink key={k} to={m.to} className={classes.link}>
          <MenuItem selected={activeRoute(m.to)}>
            {m.icon && m.icon !== "" && (
              <ListItemIcon>
                <FontAwesomeIcon icon={m.icon} fixedWidth></FontAwesomeIcon>
              </ListItemIcon>
            )}
            <ListItemText
              primary={<Typography color="inherit">{m.title}</Typography>}
            />
          </MenuItem>
        </NavLink>
      ))}
    </MenuList>
  );
};
