import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import VehicleTable from "./VehicleTable";
import VehicleDetail from "./VehicleDetail";
import { Box } from "@material-ui/core";

import { VehicleProvider } from "./VehicleProvider";
import NewCertificateDialog from "./NewCertificateDialog";
import CertificateDialog from "./CertificateDialog";

export default () => {
  const { path } = useRouteMatch();

  return (
    <VehicleProvider>
      <Box padding={1}>
        <Switch>
          <Route exact path={path} component={VehicleTable} />
          <Route path={`${path}/:id`} component={VehicleDetail} />
        </Switch>
        <Route
          path={`${path}/:id/newcertificate`}
          component={NewCertificateDialog}
        />
        <Route
          path={`${path}/:id/certificate/:cid`}
          component={CertificateDialog}
        />
      </Box>
    </VehicleProvider>
  );
};
