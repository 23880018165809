import React, { forwardRef } from "react";

import {
  Dialog,
  IconButton,
  Typography,
  Slide,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";

import _ from "lodash";

import RenderFile from "./RenderFile";

import { useQuery } from "@apollo/react-hooks";
import { loader } from "graphql.macro";
import { useHistory, useParams } from "react-router-dom";

const DLT_CONFIG = loader("./graphql/DltConfig.gql");
const DLT_CERTIFICATE = loader("./graphql/DltCertificateDetail.gql");

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default () => {
  const history = useHistory();
  const { cid } = useParams();
  const { data } = useQuery(DLT_CONFIG);
  const { data: certificateData } = useQuery(DLT_CERTIFICATE, {
    variables: {
      id: cid,
    },
  });
  const config = _.get(data, "config.0") || {};
  const dlt_certificate = _.get(certificateData, "dlt_certificate") || {};
  const payload = _.get(dlt_certificate, "payload") || {};
  let certificate = { ...dlt_certificate, ...payload };

  return (
    <>
      <Dialog
        fullScreen
        open
        onClose={() => {
          history.goBack();
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Typography>หนังสือรับรอง</Typography>
          <IconButton
            aria-label="close"
            style={{ position: "absolute", right: 4, top: 4, color: "grey" }}
            onClick={() => {
              history.goBack();
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers style={{ padding: 0 }}>
          <RenderFile data={certificate} config={config} />
        </DialogContent>
      </Dialog>
    </>
  );
};
