import React, { useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Home from "./pages/Home";
import Login from "./pages/Login";

import { AuthContext } from "./stores/AuthProvider";
import { useGoogleLogin } from "react-google-login";

import { clientId } from "./google";
import Loading from "./pages/Loading";

export default () => {
  const { isAuthenticated, userLogin, userLogout } = useContext(AuthContext);

  useGoogleLogin({
    clientId,
    isSignedIn: true,
    uxMode: "redirect",
    redirectUri: window.location.origin,
    onSuccess(response) {
      userLogin({
        userProfile: response.profileObj,
        userToken: response.tokenObj,
      });
    },
    onFailure() {
      userLogout();
    },
    onAutoLoadFinished(loaded) {
      if (!loaded) {
        userLogout();
      }
    },
  });

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login} />
        <Route
          path="/"
          render={({ location }) =>
            isAuthenticated === true ? (
              <Home />
            ) : isAuthenticated === false ? (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            ) : (
              <Loading />
            )
          }
        />
      </Switch>
    </BrowserRouter>
  );
};
