import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { loader } from "graphql.macro";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Loading from "../Loading";
import {
  Typography,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Divider,
  TextField,
  Grid,
  Button,
} from "@material-ui/core";

import _ from "lodash";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComboProvince from "../../components/ComboProvince";
import { DatePicker } from "@material-ui/pickers";
import CertificateTable from "./CertificateTable";

const VEHICLE_DETAIL = loader("./graphql/VehicleDetail.gql");
const VEHICLE_UPDATE = loader("./graphql/VehicleUpdate.gql");
const COMPANY_UPDATE = loader("./graphql/CompanyUpdate.gql");

export default () => {
  const [editDlt, setEditDlt] = useState(false);
  const [editTax, setEditTax] = useState(false);
  const [dltState, setDltState] = useState({
    dlt_vehicle_code: "",
    dlt_company_name: "",
    annual_tax_date: null,
  });
  const history = useHistory();
  const { id } = useParams();
  const { loading, data, refetch } = useQuery(VEHICLE_DETAIL, {
    variables: {
      id,
    },
  });
  const [updateVehicle, { loading: vehicle_saving }] = useMutation(
    VEHICLE_UPDATE
  );
  const [updateCompany, { loading: company_saving }] = useMutation(
    COMPANY_UPDATE
  );

  if (loading) return <Loading />;

  const { vehicle } = data;
  const { company, unit, province } = vehicle;
  const dlt_vehicle_code =
    _.get(vehicle, "dlt_vehicle_code") ||
    _.get(vehicle, "vehicle_code") ||
    _.get(vehicle, "vehicle_name") ||
    "-";
  const dlt_company_name =
    _.get(vehicle, "company.dlt_company_name") ||
    _.get(vehicle, "company.company_name") ||
    "-";
  const annual_tax_date = moment(_.get(vehicle, "annual_tax_date")) || null;

  return (
    <Card variant="outlined" square>
      <CardHeader
        avatar={<FontAwesomeIcon icon="truck" />}
        title={dlt_vehicle_code}
        subheader={dlt_company_name}
        action={
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => {
              history.goBack() || history.push("/vehicle");
            }}
          >
            <FontAwesomeIcon icon="times" fixedWidth />
          </IconButton>
        }
      />
      <Divider />
      {unit && (
        <>
          <CardContent>
            <Typography variant="h6" style={{ marginBottom: 16 }}>
              ข้อมูลกรมขนส่งทางบก
            </Typography>

            <Grid container spacing={2} style={{ marginBottom: 8 }}>
              <Grid item xs={12} sm={8} md={9}>
                {editDlt ? (
                  <TextField
                    required
                    size="small"
                    variant="outlined"
                    label="ชื่อผู้ประกอบการขนส่ง/เจ้าของรถ"
                    disabled={!editDlt || vehicle_saving || company_saving}
                    value={dltState.dlt_company_name}
                    fullWidth
                    onChange={(event) => {
                      setDltState({
                        ...dltState,
                        dlt_company_name: event.target.value,
                      });
                    }}
                  />
                ) : (
                  <TextField
                    required
                    size="small"
                    variant="outlined"
                    label="ชื่อผู้ประกอบการขนส่ง/เจ้าของรถ"
                    disabled={!editDlt || vehicle_saving || company_saving}
                    value={dlt_company_name}
                    fullWidth
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <DatePicker
                  label="วันที่ติดตั้ง"
                  inputVariant="outlined"
                  format="D MMMM yyyy"
                  size="small"
                  fullWidth
                  disabled
                  autoOk
                  value={
                    !_.get(unit, "setup_date")
                      ? new Date()
                      : new Date(_.get(unit, "setup_date"))
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom: 8 }}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {editDlt ? (
                  <TextField
                    required
                    size="small"
                    variant="outlined"
                    label="เลขทะเบียนรถ"
                    disabled={!editDlt || vehicle_saving || company_saving}
                    value={dltState.dlt_vehicle_code}
                    fullWidth
                    onChange={(event) => {
                      setDltState({
                        ...dltState,
                        dlt_vehicle_code: event.target.value,
                      });
                    }}
                  />
                ) : (
                  <TextField
                    required
                    size="small"
                    variant="outlined"
                    label="เลขทะเบียนรถ"
                    disabled
                    value={dlt_vehicle_code}
                    fullWidth
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <ComboProvince
                  size="small"
                  disabled
                  inputValue={_.get(province, "province_name")}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  required
                  disabled
                  size="small"
                  variant="outlined"
                  label="หมายเลขคัทซี"
                  defaultValue={_.get(unit, "vehicle_chassis_no") || "-"}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom: 8 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  size="small"
                  variant="outlined"
                  label="การรับรองจากกรมขนส่งทางบก เลขที่"
                  defaultValue={_.get(unit, "model.certification_id") || "-"}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  required
                  size="small"
                  variant="outlined"
                  label="หมายเลขเครื่อง"
                  defaultValue={_.get(unit, "unit_id") || "-"}
                  disabled
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  required
                  size="small"
                  variant="outlined"
                  label="ชนิด กล่อง"
                  defaultValue={_.get(unit, "model.box_type") || "-"}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  required
                  size="small"
                  variant="outlined"
                  label="แบบ กล่อง"
                  defaultValue={_.get(unit, "model.box_model") || "-"}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  required
                  size="small"
                  variant="outlined"
                  label="ชนิด เครื่องอ่าน"
                  defaultValue={_.get(unit, "model.edc_type") || "-"}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  required
                  size="small"
                  variant="outlined"
                  label="แบบ เครื่องอ่าน"
                  defaultValue={_.get(unit, "model.edc_model") || "-"}
                  disabled
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              {editDlt ? (
                <>
                  <Grid item xs={6} sm={3} md={2} lg={1}>
                    <Button
                      variant="contained"
                      disableElevation
                      fullWidth
                      disabled={vehicle_saving || company_saving}
                      onClick={() => {
                        refetch().then(() => {
                          setDltState({
                            dlt_company_name,
                            dlt_vehicle_code,
                          });
                          setEditDlt(false);
                        });
                      }}
                    >
                      ยกเลิก
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={3} md={2} lg={1}>
                    <Button
                      variant="contained"
                      disableElevation
                      fullWidth
                      color="primary"
                      disabled={vehicle_saving || company_saving}
                      onClick={() => {
                        updateVehicle({
                          variables: {
                            id: _.get(vehicle, "vehicle_id"),
                            data: {
                              dlt_vehicle_code:
                                dltState.dlt_vehicle_code || dlt_vehicle_code,
                            },
                          },
                        });
                        updateCompany({
                          variables: {
                            id: _.get(company, "company_id"),
                            data: {
                              dlt_company_name:
                                dltState.dlt_company_name || dlt_company_name,
                            },
                          },
                        });

                        if (!vehicle_saving && !company_saving) {
                          refetch();

                          setEditDlt(false);
                        }
                      }}
                    >
                      บันทึก
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    disableElevation
                    fullWidth
                    disabled={editTax}
                    color="primary"
                    onClick={() => {
                      setDltState({
                        dlt_company_name,
                        dlt_vehicle_code,
                      });
                      setEditDlt(true);
                    }}
                  >
                    แก้ไขข้อมูลขนส่ง
                  </Button>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <DatePicker
                  label="กำหนดต่อภาษีประจำปี"
                  inputVariant="outlined"
                  format="D MMMM yyyy"
                  size="small"
                  fullWidth
                  disabled={!editTax}
                  autoOk
                  value={dltState.annual_tax_date || annual_tax_date}
                  onChange={(value) => {
                    setDltState({
                      annual_tax_date: value,
                    });
                  }}
                />
              </Grid>
              {editTax ? (
                <>
                  <Grid item xs={6} sm={3} md={2} lg={1}>
                    <Button
                      variant="contained"
                      disableElevation
                      fullWidth
                      disabled={vehicle_saving}
                      onClick={() => {
                        refetch().then(() => {
                          setDltState({ annual_tax_date });
                          setEditTax(false);
                        });
                      }}
                    >
                      ยกเลิก
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={3} md={2} lg={1}>
                    <Button
                      variant="contained"
                      disableElevation
                      fullWidth
                      color="primary"
                      disabled={vehicle_saving}
                      onClick={() => {
                        updateVehicle({
                          variables: {
                            id: _.get(vehicle, "vehicle_id"),
                            data: {
                              annual_tax_date: dltState.annual_tax_date.format(),
                            },
                          },
                        }).then(() => {
                          refetch().then(() => {
                            setEditTax(false);
                          });
                        });
                      }}
                    >
                      บันทึก
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    disableElevation
                    fullWidth
                    disabled={editDlt}
                    color="primary"
                    onClick={() => {
                      setDltState({
                        annual_tax_date,
                      });
                      setEditTax(true);
                    }}
                  >
                    แก้ไขกำหนดต่อภาษี
                  </Button>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />

          <CertificateTable />
        </>
      )}
    </Card>
  );
};
