import React, { createContext, useReducer } from "react";

export const AuthContext = createContext({});

const initialState = {
  stateTime: new Date(),
  isAuthenticated: null,
  userProfile: null,
  userToken: null,
};

const authReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case "USER_LOGIN":
      return {
        ...state, // copy state
        stateTime: new Date(), // set state time
        isAuthenticated: true,
        userProfile: payload.userProfile,
        userToken: payload.userToken,
      };
    case "USER_LOGOUT":
      return {
        ...state, // copy state
        stateTime: new Date(), // set state time
        isAuthenticated: false,
        userProfile: null,
        userToken: null,
      };
    default:
  }
};

export const AuthProvider = ({ children }) => {
  const [authState, authDispatch] = useReducer(authReducer, initialState);

  const { isAuthenticated, userProfile, userToken } = authState;

  const userLogin = (payload) => authDispatch({ type: "USER_LOGIN", payload });
  const userLogout = (payload) =>
    authDispatch({ type: "USER_LOGOUT", payload });

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, userProfile, userToken, userLogin, userLogout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
