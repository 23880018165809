import React, { createContext, useReducer } from "react";

export const VehicleContext = createContext({});

const initialState = {
  stateTime: new Date(),
  dltEnable: true,
  taxDate: new Date(),
};

const vehicleReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_FILTER":
      return {
        ...state, // copy state
        stateTime: new Date(), // set state time
        ...payload,
      };
    case "CLEAR_FILTER":
      const { stateTime, ...states } = initialState;
      return {
        ...state, // copy state
        stateTime: new Date(), // set state time
        ...states,
      };
    default:
  }
};

export const VehicleProvider = ({ children }) => {
  const [vehicleState, vehicleDispatch] = useReducer(
    vehicleReducer,
    initialState
  );

  const { stateTime, ...states } = vehicleState;

  const setFilter = (payload) =>
    vehicleDispatch({ type: "SET_FILTER", payload });
  const clearFilter = (payload) =>
    vehicleDispatch({ type: "CLEAR_FILTER", payload });

  return (
    <VehicleContext.Provider
      value={{ ...states, vehicleState, setFilter, clearFilter }}
    >
      {children}
    </VehicleContext.Provider>
  );
};
