import React, { useState } from "react";

import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

export default (props) => {
  const [open, setOpen] = useState(false);
  const options = [
    { id: true, name: "ใช่" },
    { id: false, name: "ไม่ใช่" },
  ];

  return (
    <Autocomplete
      {...props}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField {...params} label="มีหมายเหตุ" variant="outlined" />
      )}
    />
  );
};
