import React from "react";
import {
  makeStyles,
  Drawer,
  Divider,
  Hidden,
  SwipeableDrawer,
} from "@material-ui/core";
import DrawerHeader from "./DrawerHeader";
import DrawerMenu from "./DrawerMenu";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));

const drawer = (
  <>
    <DrawerHeader />
    <Divider />
    <DrawerMenu />
  </>
);

export default ({ mobileOpen, setMobileOpen }) => {
  const classes = useStyles();

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <Hidden lgUp implementation="css">
        <SwipeableDrawer
          variant="temporary"
          container={container}
          open={mobileOpen}
          onOpen={handleDrawerToggle}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </SwipeableDrawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          variant="persistent"
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          open
        >
          <div className={classes.appBarSpacer} />
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
};
