import React, { useState } from "react";

import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, TextField } from "@material-ui/core";

import _ from "lodash";

import { useQuery } from "@apollo/react-hooks";
import { loader } from "graphql.macro";

const DLT_SIGNER = loader("./graphql/SignerList.gql");

export default (props) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const { loading } = useQuery(DLT_SIGNER, {
    variables: {
      order_by: [{ signer_name: "asc" }],
    },
    onCompleted(data) {
      setOptions(_.get(data, "dlt_signer") || []);
    },
  });

  return (
    <Autocomplete
      {...props}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options.sort(
        (a, b) =>
          -b.signer_name[0]
            .toUpperCase()
            .localeCompare(a.signer_name[0].toUpperCase())
      )}
      getOptionSelected={(option, value) => {
        return option.id === value.id;
      }}
      getOptionLabel={(option) => {
        return option.signer_name;
      }}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="ผู้มีอำนาจลงนาม"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
