import React, { useState } from "react";

import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, TextField } from "@material-ui/core";

import _ from "lodash";

import { useQuery } from "@apollo/react-hooks";
import { loader } from "graphql.macro";

const CATEGORIES = loader("./graphql/CategoryList.gql");

export default ({ projectId, ...props }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const { loading } = useQuery(CATEGORIES, {
    skip: !open || !projectId,
    variables: {
      where: {
        ...(projectId
          ? {
              project_id: {
                _eq: projectId,
              },
            }
          : {}),
      },
      order_by: [{ category_name: "asc" }],
    },
    onCompleted(data) {
      setOptions(_.get(data, "categories") || []);
    },
  });

  return (
    <Autocomplete
      {...props}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options.sort(
        (a, b) =>
          -(b.category_name_dlt || b.category_name)[0]
            .toUpperCase()
            .localeCompare(
              (a.category_name_dlt || a.category_name)[0].toUpperCase()
            )
      )}
      getOptionSelected={(option, value) =>
        option.category_id === value.category_id
      }
      getOptionLabel={(option) =>
        option.category_name_dlt || option.category_name
      }
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="หน่วยงาน"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
