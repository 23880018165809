import React, { useState } from "react";

import { Autocomplete } from "@material-ui/lab";
import { TextField, Avatar } from "@material-ui/core";

export default (props) => {
  const [open, setOpen] = useState(false);
  const options = [
    { id: "S", color: "blue", name: "ดับเครื่อง" },
    { id: "I", color: "orange", name: "ติดเครื่อง" },
    { id: "M", color: "green", name: "เคลื่อนที่" },
    { id: "O", color: "red", name: "เกินความเร็ว" },
    { id: "G", color: "purple", name: "ไร้สัญญาณ" },
    { id: "N", color: "grey", name: "ไร้ข้อมูล" },
  ];

  return (
    <Autocomplete
      {...props}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      renderOption={(option) => (
        <>
          <Avatar
            style={{
              width: 24,
              height: 24,
              marginRight: 16,
              fontSize: 14,
              backgroundColor: option.color,
            }}
          >
            {option.id}
          </Avatar>
          {option.name}
        </>
      )}
      renderInput={(params) => (
        <TextField {...params} label="สถานะรถ" variant="outlined" />
      )}
    />
  );
};
