import React, { useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Link, Typography, Grid, Box, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GoogleLogin } from "react-google-login";

import { AuthContext } from "../stores/AuthProvider";
import { clientId } from "../google";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://xsense.co.th/">
        Xsense
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random?logistics)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { userLogin } = useContext(AuthContext);
  const { from } = location.state || { from: { pathname: "/" } };

  const onLoginSuccess = (response) => {
    userLogin({
      userProfile: response.profileObj,
      userToken: response.tokenObj,
    });
    history.replace(from);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          height="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <img
            alt="บริษัท เอ็กเซ้นส์ อินฟอร์เมชั่น เซอร์วิส จำกัด"
            width="240"
            src="https://cdn.xsense.co.th/static/logo04.png"
          ></img>
          <Box mt={2}>
            <Typography variant="h5">ระบบจัดการข้อมูลหลังบ้าน</Typography>
          </Box>
          <Box mt={3}>
            <GoogleLogin
              buttonText="เข้าระบบด้วยอีเมล @the-xsense.com"
              clientId={clientId}
              onSuccess={onLoginSuccess}
              redirectUri={window.location.origin}
              uxMode="redirect"
              theme="dark"
              isSignedIn
            />
          </Box>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
