import React, { useEffect, useState } from "react";

import {
  PDFViewer,
  Font,
  Page,
  Text,
  Document,
  StyleSheet,
  View,
} from "@react-pdf/renderer";

import _ from "lodash";
import moment from "moment";

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "THSarabun",
    flexDirection: "column",
    padding: 64,
    paddingTop: 78,
    lineHeight: 1,
  },
  title: {
    textAlign: "center",
    fontWeight: 700,
    paddingBottom: 8,
  },
  wrap: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  body: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  paragraph: {
    textIndent: 32,
    lineHeight: 1.2,
    textAlign: "justify",
  },
  right: {
    flexDirection: "row-reverse",
  },
  row: {
    flexDirection: "row",
    paddingTop: 4,
  },
  blank: {
    width: 32,
  },
  field: {
    fontWeight: "bold",
  },
  value: {
    fontWeight: "normal",
    borderBottomWidth: 1,
    borderBottomStyle: "dotted",
    flexGrow: 1,
    paddingLeft: 8,
  },
  word: {
    flexGrow: 1,
    textAlign: "right",
  },
  signer: {
    width: 160,
    textAlign: "center",
  },
  signature: {
    width: 160,
    borderBottomWidth: 1,
    borderBottomStyle: "dotted",
    textAlign: "center",
  },
  underline: {
    textDecoration: "underline",
    textDecorationStyle: "dotted",
  },
});

// Register font
Font.register({
  family: "THSarabun",
  fonts: [
    {
      src: "https://cdn.xsense.co.th/static/THSarabunNew/THSarabunNew.ttf",
      fontStyle: "normal",
      fontWeight: 400,
    },
    {
      src:
        "https://cdn.xsense.co.th/static/THSarabunNew/THSarabunNew_Italic.ttf",
      fontStyle: "italic",
      fontWeight: 400,
    },
    {
      src: "https://cdn.xsense.co.th/static/THSarabunNew/THSarabunNew_Bold.ttf",
      fontStyle: "normal",
      fontWeight: 700,
    },
    {
      src:
        "https://cdn.xsense.co.th/static/THSarabunNew/THSarabunNew_BoldItalic.ttf",
      fontStyle: "italic",
      fontWeight: 700,
    },
  ],
});

// Create Document Component
export default ({ data, config }) => {
  const size = useWindowSize();
  let { title, name, address, phone, fax, acknowledge, responsible } =
    config || {};
  let {
    year,
    number,
    certification_id,
    unit_id,
    dlt_company_name,
    dlt_vehicle_code,
    vehicle_chassis_no,
    province_name,
    box_type,
    box_model,
    edc_type,
    edc_model,
    setup_date,
    signer,
    position,
    create_uid,
    created_at,
    remark,
  } = data || {};
  let setup_time = [
    moment(setup_date).format("D MMMM"),
    moment(setup_date).year() + 543,
  ];
  let number_value = `XS-${year}${_.padStart(number, 4, "0")}`;

  return (
    <PDFViewer width="100%" height={size.height - 64} style={{ border: 0 }}>
      <Document title={title || "-"} author={create_uid}>
        <Page style={styles.page}>
          <Text style={styles.title}>{title || "-"}</Text>
          <Text style={styles.wrap}>
            เลขที่หนังสือ <Text style={styles.underline}>{number_value}</Text>
          </Text>
          <View style={styles.row}>
            <Text style={styles.blank}></Text>
            <Text style={styles.value}>{name || "-"}</Text>
          </View>
          <View style={styles.row}>
            <Text>ที่อยู่/ที่ตั้งเลขที่</Text>
            <Text style={styles.value}>{_.get(address, "number") || "-"}</Text>
            <Text>หมู่ที่</Text>
            <Text style={styles.value}>{_.get(address, "moo") || "-"}</Text>
            <Text>ตรอก/ซอย</Text>
            <Text style={styles.value}>{_.get(address, "alley") || "-"}</Text>
            <Text>ถนน</Text>
            <Text style={styles.value}>{_.get(address, "road") || "-"}</Text>
          </View>
          <View style={styles.row}>
            <Text>ตำบล/แขวง</Text>
            <Text style={styles.value}>{_.get(address, "tumbon") || "-"}</Text>
            <Text>อำเภอ/เขต</Text>
            <Text style={styles.value}>
              {_.get(address, "district") || "-"}
            </Text>
            <Text>จังหวัด</Text>
            <Text style={styles.value}>
              {_.get(address, "province") || "-"}
            </Text>
          </View>
          <View style={styles.row}>
            <Text>รหัสไปรษณีย์</Text>
            <Text style={styles.value}>{_.get(address, "zip") || "-"}</Text>
            <Text>โทรศัพท์</Text>
            <Text style={styles.value}>{phone || "-"}</Text>
            <Text>โทรสาร</Text>
            <Text style={styles.value}>{fax || "-"}</Text>
          </View>
          <View style={styles.row}>
            <Text>
              ได้ติดตั้งเครื่องบันทึกข้อมูลการเดินทางของรถรายละเอียดดังนี้
            </Text>
          </View>
          <View style={styles.wrap}>
            <View style={styles.row}>
              <Text style={styles.blank}></Text>
              <Text style={styles.field}>การรับรองจากกรมขนส่งทางบก เลขที่</Text>
              <Text style={styles.value}>{certification_id || "-"}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.blank}></Text>
              <Text style={styles.field}>ชนิด</Text>
              <Text style={styles.value}>{box_type || "-"}</Text>
              <Text style={styles.field}>แบบ</Text>
              <Text style={styles.value}>{box_model || "-"}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.blank}></Text>
              <Text style={styles.field}>หมายเลขเครื่อง</Text>
              <Text style={styles.value}>{unit_id || "-"}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.blank}></Text>
              <Text style={styles.field}>เครื่องอ่านบัตรแถบแม่เหล็ก ชนิด</Text>
              <Text style={styles.value}>{edc_type || "-"}</Text>
              <Text style={styles.field}>แบบ</Text>
              <Text style={styles.value}>{edc_model || "-"}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.blank}></Text>
              <Text style={styles.field}>วันที่ติดตั้ง</Text>
              <Text style={styles.value}>{setup_time.join(" ") || "-"}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.blank}></Text>
              <Text style={styles.field}>ชื่อผู้ประกอบการขนส่ง/เจ้าของรถ</Text>
              <Text style={styles.value}>{dlt_company_name || "-"}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.blank}></Text>
              <Text style={styles.field}>หมายเลขทะเบียน</Text>
              <Text style={styles.value}>
                {dlt_vehicle_code || "-"} {province_name || "-"}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.blank}></Text>
              <Text style={styles.field}>หมายเลขคัสซี</Text>
              <Text style={styles.value}>{vehicle_chassis_no || "-"}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.blank}></Text>
              <Text style={styles.field}>หมายเหตุ</Text>
              <Text style={styles.value}>{remark || "-"}</Text>
            </View>
          </View>
          <View style={styles.wrap}>
            <Text style={styles.paragraph}>{acknowledge || "-"}</Text>
            <Text style={styles.paragraph}>{responsible || "-"}</Text>
          </View>
          <View style={styles.body}>
            <View style={styles.right}>
              <Text style={styles.signature}>
                {moment(created_at).format("D MMMM ")}
                {moment(created_at).year() + 543}
              </Text>
              <Text style={styles.word}>ออกให้ ณ วันที่</Text>
            </View>
          </View>
          <View style={styles.wrap}>
            <View style={styles.right}>
              <Text style={styles.signature}></Text>
              <Text style={styles.word}>ลงชื่อ</Text>
            </View>
            <View style={styles.right}>
              <Text style={styles.signer}>({signer || "-"})</Text>
            </View>
            <View style={styles.right}>
              <Text style={styles.signer}>{position || "-"}</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

// Hook
function useWindowSize() {
  const isClient = typeof window === "object";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [getSize, isClient]); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
