import React, { useState } from "react";

import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, TextField } from "@material-ui/core";

import _ from "lodash";

import { useQuery } from "@apollo/react-hooks";
import { loader } from "graphql.macro";

const PAYS = loader("./graphql/PayList.gql");

export default ({ projectId, ...props }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const { loading } = useQuery(PAYS, {
    variables: {
      order_by: [{ pay_name: "asc" }],
    },
    onCompleted(data) {
      setOptions(_.get(data, "pays") || []);
    },
  });

  return (
    <Autocomplete
      {...props}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options.sort(
        (a, b) =>
          -b.pay_name[0]
            .toUpperCase()
            .localeCompare(a.pay_name[0].toUpperCase())
      )}
      getOptionSelected={(option, value) => option.pay_id === value.pay_id}
      getOptionLabel={(option) => option.pay_name}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="การวางบิล"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
