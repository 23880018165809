import React from "react";
import { CircularProgress, Box, Typography } from "@material-ui/core";

export default () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
      <Box marginTop={2}>
        <Typography>โปรดรอสักครู่</Typography>
      </Box>
    </Box>
  );
};
