import React, { useContext } from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Button,
} from "@material-ui/core";
import { AuthContext } from "../stores/AuthProvider";
import { GoogleLogout } from "react-google-login";
import { clientId } from "../google";
import { ExitToApp } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

export default () => {
  const history = useHistory();
  const { userProfile, userLogout } = useContext(AuthContext);

  const onLogoutSuccess = () => {
    userLogout();
    history.replace("/login");
  };

  return (
    <List>
      <ListItem>
        <ListItemAvatar>
          <Avatar src={userProfile.imageUrl} />
        </ListItemAvatar>
        <ListItemText
          primary={userProfile.name}
          secondary={
            <GoogleLogout
              clientId={clientId}
              onLogoutSuccess={onLogoutSuccess}
              render={(renderProps) => (
                <Button
                  color="inherit"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  endIcon={<ExitToApp />}
                >
                  ออกจากระบบ
                </Button>
              )}
            />
          }
        />
      </ListItem>
    </List>
  );
};
